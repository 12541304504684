import { Box } from "@mui/material";
import Article from "./article";
import { articleData } from "./articleData";

const Articles = () => {
  return (
    <Box sx={{ flexGrow: 1, backgroundColor: "transparent", paddingBottom: "150px" }}>
      {articleData.map((_article, index) => (
        <Article key={`article_${index}`} articleNumber={index} />
      ))}
    </Box>
  );
};

export default Articles;
