import { Box, Button, Card, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneIcon from "@mui/icons-material/Phone";
import BottomBarBg from "../../img/BottomBarBg.jpg";
import Logo from "../../img/logo.png";

const Contact = () => {
  const isBigScreen = useMediaQuery(useTheme().breakpoints.up("lg"));
  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        height: "400px",
        width: "100%",
        backgroundImage: `url(${BottomBarBg})`,
        backgroundColor: "black",
        color: "white",
      }}
    >
      <Grid
        container
        spacing={1}
        id="scrollId_4"
        sx={{
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        {isBigScreen ? (
          <>
            <Grid item md={1}>
              <img
                style={{ maxHeight: "200px", maxWidth: "-webkit-fill-available" }}
                alt="img"
                src={Logo}
              />
            </Grid>
            <Grid item md={8}>
              <Box p={4}>
                <Typography variant="h3" sx={{ fontWeight: 600 }} p={1}>
                  Neem contact op met Paintbusters
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 600 }} p={2}>
                  Hey! We kijken altijd uit naar een nieuw project. Vraag nu gratis en vrijblijvend
                  een offerte aan.
                </Typography>
              </Box>
            </Grid>
          </>
        ) : null}
        <Grid item lg={3}>
          <Box
            sx={{
              width: "300px",
              marginTop: "-100px",
            }}
          >
            <Card
              elevation={2}
              sx={{
                backgroundColor: "white",
                borderRadius: "20px",
                minHeight: "350px",
                padding: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography align={"center"} sx={{ fontWeight: 600 }} p={2}>
                Bel ons
              </Typography>

              <Button
                variant="outlined"
                startIcon={<PhoneIcon />}
                onClick={() => window.open("tel:0031643258997")}
              >
                06 - 43258997
              </Button>

              <Typography align={"center"} sx={{ fontWeight: 600 }} p={2}>
                ..of stuur een WhatsApp
              </Typography>
              <Button
                variant="outlined"
                startIcon={<WhatsAppIcon />}
                onClick={() => window.open("https://wa.me/0031643258997")}
              >
                06 - 43258997
              </Button>
              <Typography align={"center"} sx={{ fontWeight: 600 }} p={2}>
                Bereikbaarheid klantenservice
              </Typography>
              <Typography align={"center"}>Ma - Vr: 08:00 tot 17:00</Typography>
              <Typography align={"center"}>Za - Zo: gesloten</Typography>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Contact;
