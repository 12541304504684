import Articles from "./articles";
import BodyHeader from "./bodyHeader";

const Body = () => {
  return (
    <>
      <BodyHeader />
      <Articles />
    </>
  );
};

export default Body;
