import RoomOne from "../../img/room1.jpg";
import RoomTwo from "../../img/room2.jpg";
import RoomThree from "../../img/room3.jpg";
import RoomFour from "../../img/room3.jpg";

export const articleData: { title: string; text: string; image: any }[] = [
  {
    title: "Paintbusters",
    text: "Wandafwerking maakt de ruimte en doet onbewust heel veel met mensen en gemoedstoestanden. Bent u toe aan een frisse wandafwerking? Met Paintbusters spuitwerk krijgt u een snel en super strak resultaat. Met een toenemende hoeveelheid ervaring kan Paintbusters u helpen met spuitwerk, schilderwerk en stucwerk. Door de populariteit van de snelle & mooie levering is Paintbusters gespecialiseerd in het werken met het spuiten van latex. Dit garandeert een prachtig eindresultaat en is in u voordeel vaak sneller & voordeliger dan schilderwerk. Paintbusters werkt uitsluitend met de modernste verfproducten en stellen maximale kwaliteitseisen. Onze service is beschikbaar door het hele land en wij stellen graag een offerte voor u op om u hopelijk in de nabije toekomst van dienst te kunnen zijn",
    image: RoomOne,
  },
  {
    title: "Waarom latex?",
    text: "Afhankelijk van de situatie en het m2 oppervlak komt latex vaak tot wel 50% voordeliger uit in vergelijking met schilderwerk. Een ander een bijkomend voordeel in vergelijking met schilderwerk is dat de werkzaamheden sneller geklaard zijn, wat minder overlast met zich meebrengt voor u en eventueel uw familie of werknemers. Omdat kwaliteit voorop staat bij onze werkzaamheden, zorgen wij voor een goede voorbereiding voordat we beginnen. Dit zorgt ervoor dat wij garant kunnen staan voor keer op keer een prachtig eindresultaat.",
    image: RoomTwo,
  },
  {
    title: "Wanden spuiten",
    text: "Vakkundig spuit Paintbusters de gewenste kamers of onderdelen in woning of bedrijfspand. Het enorme voordeel van latex spuiten is dat het mogelijk is op vele verschillende oppervlakken zoals hout, staal en steen en het een egaal, streep loos resultaat oplevert. Door de egale afwerklaag van latex, die naar keuze gemaakt kan worden, kan latex heel praktisch zijn, denk aan afneembare of anti-vuil latexlagen voor in ruimtes zoals de badkamer of keuken.",
    image: RoomThree,
  },
  {
    title: "Plafond spuiten",
    text: "Een plafond sauzen is in het algemeen een lastige zelfstandige klus. Met onze hulp krijgt u het eindresultaat waar u op heeft gerekend. Het verschil tussen onze expertise en zelfstandig sauzen is te zien met een streep loze dekking en een strakke plafond-wandafwerking. Ook hierbij treffen wij de benodigde voorbereiding om een egaal gespoten plafond te leveren die voldoet aan u verwachtingen. Ons meedenkende team staat u graag te hulp met het opstellen van een offerte of het beantwoorden van uw vragen.",
    image: RoomFour,
  },
];
