import { Box } from "@mui/material";
import Header from "./header";
import Body from "./body";
import Contact from "./contact";
import LivingRoom from "../img/livingroom.jpeg";

const Page = () => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundImage: `url(${LivingRoom})`,
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
        backgroundColor: "#f5f5f5",
        height: "100%",
        minWidth: "100%",
      }}
    >
      <Header />
      <Body />
      <Contact />
    </Box>
  );
};

export default Page;
