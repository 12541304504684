import { Box, Fade, Typography } from "@mui/material";

const BodyHeader = () => (
  <Box sx={{ flexGrow: 1, height: "300px", alignItems: "center" }}>
    <Fade>
      <>
        <Typography
          variant="h4"
          align={"center"}
          sx={{
            color: "white",
            textShadow: "1px 2px 3px black",
            paddingTop: "100px",
          }}
        >
          Uw huis laten spuiten?
        </Typography>
        <Typography
          variant="h6"
          align={"center"}
          sx={{
            color: "white",
            textShadow: "1px 2px 3px black",
          }}
        >
          Vakkundig en voordelig!
        </Typography>
      </>
    </Fade>
  </Box>
);

export default BodyHeader;
