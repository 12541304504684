import {
  Card,
  Box,
  CardContent,
  Typography,
  CardMedia,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { articleData } from "./articleData";

const Article = ({ articleNumber }: { articleNumber: number }) => {
  const article = articleData[articleNumber];
  const smallScreenSize = useMediaQuery(useTheme().breakpoints.down("md"));

  const TextContainer = () => (
    <Box sx={{ display: "flex", flexDirection: "column", maxWidth: "450px", padding: "20px" }}>
      <CardContent sx={{ flexGrow: "1" }}>
        <Typography
          component="div"
          variant="h5"
          id={`scrollId_${articleNumber}`}
          sx={{ fontWeight: "bold" }}
        >
          {article.title}
        </Typography>
        <Typography variant="body2" color="text.secondary" component="div">
          {article.text}
        </Typography>
      </CardContent>
      <Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}></Box>
    </Box>
  );

  const ImageContainer = () => (
    <Box
      sx={{
        display: "flex",
        maxWidth: "450px",
        maxHeight: "400px",
        objectFit: "contain",
        padding: "20px",
      }}
    >
      <CardMedia
        component="img"
        sx={{ flexGrow: "1", borderRadius: "10px" }}
        image={article.image}
        alt="img"
      />
    </Box>
  );

  const orderedComponents: () => JSX.Element[] = () => {
    const components = [<TextContainer key="text" />, <ImageContainer key="image" />];
    const isOddNumber = articleNumber % 2 === 1;

    return isOddNumber || smallScreenSize ? components : components.reverse();
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Card
        sx={{
          display: "flex",
          maxWidth: "1000px",
          justifyContent: "center",
          alignItems: "center",
          margin: "20px",
          flexDirection: smallScreenSize ? "column" : "row",
        }}
      >
        {orderedComponents()}
      </Card>
    </Box>
  );
};

export default Article;
