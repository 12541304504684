import React, { useEffect } from "react";
import "./App.css";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { PaletteMode } from "@mui/material";
import { CssBaseline } from "@mui/material";
import Spa from "./spa";

export const ColorModeContext = React.createContext({
  currentMode: "dark",
  toggleColorMode: () => {},
});

const App = () => {
  const [mode, setMode] = React.useState<PaletteMode>();
  
  const storedThemeValue = localStorage.getItem("theme");
  const typeSafeValue: PaletteMode =
    storedThemeValue === "dark" || storedThemeValue === "light" ? storedThemeValue : null;
  const systemThemePreference: PaletteMode = useMediaQuery("(prefers-color-scheme: dark)")
    ? "dark"
    : "light";

  useEffect(() => {
    if (!mode) {
      const newMode = typeSafeValue || systemThemePreference;
      localStorage.setItem("theme", newMode);
      setMode(newMode);
      return;
    }
    localStorage.setItem("theme", mode);
  }, [mode, systemThemePreference, typeSafeValue]);

  const theme = React.useMemo(() => createTheme({ palette: { mode: "light" } }), []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Spa />
    </ThemeProvider>
  );
};

export default App;
