import {
  AppBar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Slide,
  Toolbar,
  useScrollTrigger,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from "@mui/icons-material/Call";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import TopBarBg from "../../img/TopBarBg.jpg";
import Logo from "../../img/logo.png";
import { useState } from "react";

export type MenuRoutes = { title: string }[];

const menuRoutes: MenuRoutes = [
  { title: "Over PaintBusters" },
  { title: "Waarom Latex" },
  { title: "Wanden spuiten" },
  { title: "Plafond spuiten" },
  { title: "Contact" },
];

const Header = () => {
  const theme = useTheme();
  const bigScreenSize = useMediaQuery(theme.breakpoints.up("lg"));
  const trigger = useScrollTrigger({
    threshold: bigScreenSize ? 100 : undefined,
  });

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const scrollIntoView = (index: number) => {
    const element = document.getElementById(`scrollId_${index}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <>
      {bigScreenSize ? (
        <Slide appear={false} in={!trigger} direction={"down"}>
          <AppBar
            elevation={2}
            sx={{
              backgroundImage: `url(${TopBarBg})`,
              backgroundPosition: "center",
              backgroundColor: "#2f4f4f",
            }}
          >
            <Toolbar
              sx={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "center",
                backgroundColor: "transparent",
              }}
            >
              <img style={{ maxHeight: "100px" }} alt="img" src={Logo} />
            </Toolbar>
            <Toolbar disableGutters>
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "transparent",
                  minHeight: "64px",
                }}
              >
                {menuRoutes.map((route, index) => (
                  <Button
                    key={route.title}
                    onClick={() => scrollIntoView(index)}
                    sx={{ px: 2, color: "white", display: "block" }}
                    size="large"
                  >
                    {route.title}
                  </Button>
                ))}

                <Button
                  onClick={() => scrollIntoView(4)}
                  sx={{ px: 2, color: "white", display: "flex" }}
                  startIcon={<CallIcon sx={{ margin: 0 }} />}
                  size="large"
                >
                  Bel
                </Button>
                <Button
                  onClick={() => scrollIntoView(4)}
                  sx={{ px: 2, color: "white", display: "flex" }}
                  size="large"
                  startIcon={<WhatsAppIcon sx={{ margin: 0 }} />}
                >
                  App
                </Button>
              </Box>
            </Toolbar>
          </AppBar>
        </Slide>
      ) : (
        <AppBar
          elevation={2}
          sx={{
            backgroundImage: `url(${TopBarBg})`,
            backgroundPosition: "center",
          }}
          position={"fixed"}
        >
          <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
            <img style={{ maxHeight: "100px" }} alt="img" src={Logo} />
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              {anchorElNav ? (
                <MenuOpenIcon sx={{ height: "30px", width: "30px" }} />
              ) : (
                <MenuIcon sx={{ height: "30px", width: "30px" }} />
              )}
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: "block",
              }}
            >
              {menuRoutes.map((route, index) => (
                <MenuItem
                  key={route.title}
                  onClick={() => scrollIntoView(index)}
                >
                  {route.title}
                </MenuItem>
              ))}
            </Menu>
          </Toolbar>
        </AppBar>
      )}
      <Box sx={{ flexGrow: 1, minHeight: bigScreenSize ? "164px" : "100px" }} />
    </>
  );
};

export default Header;
